<template>
    <div class="row">
        <div class="col">
            <product-info-modal :product="selectedProduct"></product-info-modal>

            <error-message :error="error" :timeout="true" :toast="true" positioning="floating" classes="mt-3" enter-animation="slideInLeft"
                           leave-animation="slideOutLeft"></error-message>

            <div class="row">
                <div class="col-md">
                    <div class="row">
                        <div class="col">
                            <h3 class="d-inline-block">{{ __('model.model.name', { count: 2 }) | capitalize }}</h3>
                            <button type="button" class="btn btn-primary btn-sm d-inline-block"
                                    :title="__('button.reload', { model: __('model.model.name_plural') }) | capitalize"
                                    @click="getModels" :disabled="globalVars.isLoading && models.length === 0">
                                <i class="fas fa-sync" :class="{ 'fa-spin': globalVars.isLoading && models.length === 0 }"></i>
                            </button>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col scrollable scrollable-sm">
                            <transition enter-active-class="animated fadeIn" leave-active-class="animated fadeOut" mode="out-in">
                                <div v-if="models != null && models.length > 0" key="models-found">
                                    <div class="list-group list-group-item-lg">
                                        <div v-for="model in models" tabindex="0"
                                             class="list-group-item list-group-item-action hover hover-pointer"
                                             @mouseover="$set(model, 'hovering', true)" @mouseleave="$set(model, 'hovering', false)"
                                             @focusin="$set(model, 'hovering', true)" @focusout="$set(model, 'hovering', false)">
                                            <div class="row">
                                                <div class="col-8">
                                                    <!-- Model info -->
                                                    <transition enter-active-class="animated fadeInRight position-absolute"
                                                                leave-active-class="animated fadeOutRight position-absolute">
                                                        <span v-show="!model.edit">{{ model.number }}: {{ model.name }}</span>
                                                    </transition>

                                                    <!-- Edit Model info-->
                                                    <transition enter-active-class="animated fadeInLeft" leave-active-class="animated fadeOutLeft">
                                                        <div class="input-group" v-show="model.edit">
                                                            <input type="text" class="form-control"
                                                                   :title="__('title.input.model_field', {
                                                                               field: __('model.model.attr.name'),
                                                                               model: __('model.model.name')
                                                                           }) | capitalize"
                                                                   :placeholder="__('model.model.attr.name') | capitalize"
                                                                   v-model="model.name" @keydown.enter="saveModel(model.id)" @keydown.space.stop @click.stop>
                                                            <div class="input-group-append">
                                                                <button type="button" class="btn btn-success btn-sm"
                                                                        @click.stop="saveModel(model.id)" :disabled="globalVars.isLoading">
                                                                    <i class="fas fa-save"></i>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </transition>
                                                </div>
                                                <div class="col">
                                                    <div class="float-right">
                                                        <model-crud-buttons :model-type="'model'" :model="model" event="delete-model" @delete-model="deleteModel"></model-crud-buttons>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div v-else-if="globalVars.isLoading" key="loading-models">
                                    {{ __('text.loading_models', { model: __('model.model.name', { count: 2 }) }) | capitalize }} <i class="fas fa-circle-notch fa-spin"></i>
                                </div>
                                <div v-else key="no-models">
                                    {{ __('text.no_models_found', { model: __('model.model.name', { count: 2 }) }) | capitalize }}
                                </div>
                            </transition>
                        </div>
                    </div>
                </div>

                <div class="col-md">
                    <div class="row">
                        <div class="col">
                            <h3 class="d-inline-block">{{ __('model.group.name', { count: 2 }) | capitalize }}</h3>
                            <button type="button" class="btn btn-primary btn-sm d-inline-block"
                                    :title="__('button.reload', { model: __('model.group.name_plural') }) | capitalize"
                                    @click="getGroups" :disabled="globalVars.isLoading && groups.length === 0">
                                <i class="fas fa-sync" :class="{ 'fa-spin': globalVars.isLoading && groups.length === 0 }"></i>
                            </button>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col scrollable scrollable-sm">
                            <transition enter-active-class="animated fadeIn" leave-active-class="animated fadeOut" mode="out-in">
                                <div v-if="groups != null && groups.length > 0" key="groups_not_null">
                                    <div class="list-group list-group-item-lg">
                                        <div v-for="group of groups" :key="group.id"
                                             class="list-group-item list-group-item-action hover hover-pointer"
                                             @mouseover="$set(group, 'hovering', true)" @mouseleave="$set(group, 'hovering', false)"
                                             @focusin="$set(group, 'hovering', true)" @focusout="$set(group, 'hovering', false)">
                                            <div class="row">
                                                <div class="col-8">
                                                    <transition enter-active-class="animated fadeInRight position-absolute"
                                                                leave-active-class="animated fadeOutRight position-absolute">
                                                        <span v-show="!group.edit">{{ group.number }}: {{ group.name }}</span>
                                                    </transition>

                                                    <transition enter-active-class="animated fadeInLeft" leave-active-class="animated fadeOutLeft">
                                                        <div class="input-group" v-show="group.edit">
                                                            <input type="text" class="form-control"
                                                                   :title="__('title.input.model_field', {
                                                                                   field: __('model.group.attr.name'),
                                                                                   model: __('model.group.name')
                                                                               }) | capitalize"
                                                                   :placeholder="__('model.group.attr.name') | capitalize"
                                                                   v-model="group.name" @keydown.enter="saveGroup(group.id)" @keydown.space.stop @click.stop>
                                                            <div class="input-group-append">
                                                                <button type="button" class="btn btn-success btn-sm"
                                                                        @click.stop="saveGroup(group.id)" :disabled="globalVars.isLoading">
                                                                    <i class="fas fa-save"></i>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </transition>
                                                </div>
                                                <div class="col">
                                                    <div class="float-right">
                                                        <model-crud-buttons :model-type="'group'" :model="group" event="delete-group"
                                                                            @delete-group="deleteGroup"></model-crud-buttons>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div v-else-if="globalVars.isLoading" key="loading-groups">
                                    {{ __('text.loading_models', { model: __('model.group.name', { count: 2 }) }) | capitalize }} <i class="fas fa-circle-notch fa-spin"></i>
                                </div>
                                <div v-else key="no-groups">
                                    {{ __('text.no_models_found', { model: __('model.group.name', { count: 2 }) }) | capitalize }}
                                </div>
                            </transition>
                        </div>
                    </div>
                </div>
            </div>

            <hr/>

            <div class="row">
                <div class="col">
                    <ul class="nav nav-tabs">
                        <li class="nav-item">
                            <a id="tab_products_overview_nav_item" class="nav-link active" href="#tab_products_overview" data-toggle="tab">
                                <h3 class="d-inline-block m-2">{{ __('model.product.name_plural') | capitalize }}</h3>
                                <button type="button" class="btn btn-primary btn-sm d-inline-block"
                                        :title="__('button.reload', { model: __('model.product.name_plural')}) | capitalize"
                                        @click="getProducts()" :disabled="globalVars.isLoading && products.length === 0">
                                    <i class="fas fa-sync" :class="{ 'fa-spin': globalVars.isLoading && products.length === 0 }"></i>
                                </button>
                            </a>
                        </li>
                        <li class="nav-item">
                            <a id="tab_info_files_overview_nav_item" class="nav-link" href="#tab_info_files_overview" data-toggle="tab">
                                <h3 class="d-inline-block m-2">{{ __('model.product.attr.info_file.name_plural') | capitalize }}</h3>
                                <button type="button" class="btn btn-primary btn-sm d-inline-block"
                                        :title="__('button.reload', { model: __('model.product.attr.info_file.name_plural')}) | capitalize"
                                        @click="getInfoFiles()" :disabled="globalVars.isLoading && infoFiles.length === 0">
                                    <i class="fas fa-sync" :class="{ 'fa-spin': globalVars.isLoading && infoFiles.length === 0 }"></i>
                                </button>
                            </a>
                        </li>
                    </ul>

                    <div class="tab-content mb-5">
                        <div id="tab_products_overview" class="tab-pane fade show active pt-3">
                            <div class="row">
                                <div class="col">
                                    <div class="row mb-3">
                                        <div class="col-md">
                                            <div class="form-inline">
                                                <div class="form-inline mr-2">
                                                    <div class="form-group">
                                                        <div class="input-group">
                                                            <input type="text" class="form-control"
                                                                   :title="__('admin_maintenance.title.input.filter_products')"
                                                                   :placeholder="__('admin_maintenance.title.input.filter_products')"
                                                                   v-model="searchProductNumber" @keydown.enter="filterProducts">
                                                            <div class="input-group-append">
                                                                <button type="button" class="btn btn-outline-secondary border-left-0 border" :disabled="globalVars.isLoading"
                                                                        @click="searchProductNumber = ''; filterProducts()">
                                                                    <i class="fas fa-times"></i>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <button type="button" class="btn btn-primary ml-2" @click="filterProducts">{{ __('button.filters.apply') }} <i class="fas fa-search"></i></button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <pagination-links :pagination="pagination" @switch="getProducts($event.url)"></pagination-links>

                                    <div class="row">
                                        <div class="col scrollable scrollable-lg">
                                            <table id="products-table" class="table table-hover bg-white">
                                                <thead class="thead-light sticky-top">
                                                    <tr>
                                                        <th>{{ __('model.product.attr.number') | capitalize }}</th>
                                                        <th>{{ globalVars.lang.toUpperCase() }}</th>
                                                        <th>{{ __('model.product.attr.data') | capitalize }}</th>
                                                        <th>{{ __('model.product.attr.info') | capitalize }}</th>
                                                        <th class="two-btn-width"></th>
                                                    </tr>
                                                </thead>
                                                <list-staggered tag="tbody" :disable-leave-animation="true">
                                                    <template v-if="products != null && products.length > 0">
                                                        <tr v-for="( product, index ) in products" :key="product.id" :data-index="index" tabindex="0"
                                                            @mouseover="$set(product, 'hovering', true)" @mouseleave="$set(product, 'hovering', false)"
                                                            @focusin="$set(product, 'hovering', true)" @focusout="$set(product, 'hovering', false)">
                                                            <td>
                                                                <transition enter-active-class="animated fadeInRight position-absolute"
                                                                            leave-active-class="animated fadeOutRight position-absolute">
                                                                    <span v-show="!product.edit">
                                                                        {{ product.number }}

                                                                        <template v-if="product.numbers.length > 0">
                                                                            <span class="text-muted" :title="__('model.product_number.title')">
                                                                                ({{ product.numbers.map(number => number.number).join(', ') }})
                                                                            </span>
                                                                        </template>
                                                                    </span>
                                                                </transition>

                                                                <transition enter-active-class="animated fadeInLeft" leave-active-class="animated fadeOutLeft">
                                                                    <input type="text" class="form-control"
                                                                           :title="__('title.input.model_field', {
                                                                       field: __('model.product.attr.number'),
                                                                       model: __('model.product.name')
                                                                   }) | capitalize"
                                                                           :placeholder="__('model.product.attr.number') | capitalize"
                                                                           v-show="product.edit" v-model="product.number" @keydown.enter="saveProduct(product.id)">
                                                                </transition>
                                                            </td>
                                                            <td>{{ product.name }}</td>
                                                            <td>
                                                                <transition enter-active-class="animated fadeInRight position-absolute"
                                                                            leave-active-class="animated fadeOutRight position-absolute">
                                                                    <span v-show="!product.edit">{{ product.data }}</span>
                                                                </transition>

                                                                <transition enter-active-class="animated fadeInLeft" leave-active-class="animated fadeOutLeft">
                                                                    <input type="text" class="form-control"
                                                                           :title="__('title.input.model_field', {
                                                                       field: __('model.product.attr.data'),
                                                                       model: __('model.product.name')
                                                                   }) | capitalize"
                                                                           :placeholder="__('model.product.attr.data') | capitalize"
                                                                           v-show="product.edit" v-model="product.data" @keydown.enter="saveProduct(product.id)">
                                                                </transition>
                                                            </td>
                                                            <td>
                                                                <transition enter-active-class="animated fadeInRight position-absolute"
                                                                            leave-active-class="animated fadeOutRight position-absolute">
                                                                    <span v-show="!product.edit">{{ product.info }}</span>
                                                                </transition>

                                                                <transition enter-active-class="animated fadeInLeft" leave-active-class="animated fadeOutLeft">
                                                                    <div class="input-group" v-show="product.edit">
                                                                        <input type="text" class="form-control" v-model="product.info"
                                                                               :title="__('title.input.model_field', {
                                                                           field: __('model.product.attr.info'),
                                                                           model: __('model.product.name')
                                                                       }) | capitalize"
                                                                               :placeholder="__('model.product.attr.info') | capitalize"
                                                                               @keydown.enter="saveProduct(product.id)">
                                                                        <div class="input-group-append">
                                                                            <button type="button" class="btn btn-success btn-sm"
                                                                                    @click="saveProduct(product.id)" :disabled="globalVars.isLoading">
                                                                                <i class="fas fa-save"></i>
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </transition>
                                                            </td>
                                                            <td>
                                                                <model-crud-buttons :model-type="'product'" :model="product" event="delete-product" @delete-product="deleteProduct"></model-crud-buttons>

                                                                <transition enter-active-class="animated fadeIn" leave-active-class="animated fadeOut">
                                                                    <button v-show="product.hovering" type="button" class="btn btn-info btn-sm"
                                                                            data-toggle="modal" data-target="#product-info-modal" @click="selectedProduct = product"><i class="fas fa-info-circle"></i></button>
                                                                </transition>
                                                            </td>
                                                        </tr>
                                                    </template>
                                                    <tr v-else-if="globalVars.isLoading" key="loading-products">
                                                        <td>
                                                            {{ __('text.loading_models', { model: __('model.product.name', { count: 2 }) }) | capitalize }} <i class="fas fa-circle-notch fa-spin"></i>
                                                        </td>
                                                    </tr>
                                                    <tr v-else key="no-products">
                                                        <td>
                                                            {{ __('text.no_models_found', { model: __('model.product.name', { count: 2 }) }) | capitalize }}
                                                        </td>
                                                    </tr>
                                                </list-staggered>
                                            </table>
                                        </div>
                                    </div>

                                    <pagination-links :pagination="pagination" @switch="getProducts($event.url)"></pagination-links>
                                </div>
                            </div>
                        </div>

                        <div id="tab_info_files_overview" class="tab-pane fade pt-3">
                            <template v-if="infoFiles.length > 0">
                                <div class="row">
                                    <div class="col">
                                        <div id="info-files-accordion" class="scrollable scrollable-lg">
                                            <div v-for="(infoFile, index) of infoFiles" class="card">
                                                <div class="card-header hover hover-pointer"
                                                     data-toggle="collapse" :data-target="`#info-file-${index}`">
                                                    {{ infoFile.name }}
                                                </div>

                                                <div :id="`info-file-${index}`" class="collapse" data-parent="#info-files-accordion">
                                                    <div class="card-body">
                                                        <h3>{{ __('model.product.name_plural') | capitalize }}</h3>
                                                        <div class="list-group scrollable scrollable-sm">
                                                            <div v-for="product of infoFile.products" class="list-group-item">
                                                                {{ product.number }}: {{ product.name }}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </template>
                            <template v-else-if="globalVars.isLoading">
                                {{ __('text.loading_models', { model: __('model.product.attr.info_file.name_plural') }) | capitalize }} <i class="fas fa-circle-notch fa-spin"></i>
                            </template>
                            <template v-else>
                                {{ __('text.no_models_found', { model: __('model.product.attr.info_file.name_plural') }) | capitalize }}
                            </template>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import ModelCrudButtons from '../components/ModelCrudButtons';
    import ErrorMessage from '../components/ErrorMessage';
    import PaginationLinks from '../components/PaginationLinks';
    import ListStaggered from '../transitions/ListStaggered';
    import ProductInfoModal from '../components/modals/ProductInfoModal';
    import ModelInfoModal from '../components/modals/ModelInfoModal.vue';

    export default {
        components: {
            ModelInfoModal,
            ProductInfoModal,
            ModelCrudButtons,
            ErrorMessage,
            PaginationLinks,
            ListStaggered,
        },
        data() {
            return {
                error: new _Error(),
                models: [],
                groups: [],
                products: [],
                infoFiles: [],
                pagination: new Pagination(),
                searchProductNumber: '',
                selectedProduct: new Product(),

                productsChanged: false,
            };
        },
        created() {
            this.getModels();
            this.getGroups();
            this.getProducts();
        },
        mounted() {
            $('#tab_info_files_overview_nav_item').on('show.bs.tab', () => {
                if (this.productsChanged) {
                    this.getInfoFiles();
                }

                this.productsChanged = false;
            });
        },
        methods: {
            getModels() {
                this.models = [];

                axios
                    .get('/api/models')
                    .then(response => {
                        if (!_.isEmpty(response)) {
                            if (response.status === HTTP_OK) {
                                this.models = response.data.result;
                            }
                        }
                    })
                    .catch(error => {
                        this.error = this.createErrorFromRequest(error);
                    })
                ;
            },
            saveModel(modelID) {
                this.error = new _Error();

                if (!_.isNil(modelID) && _.isInteger(modelID)) {
                    let foundModel = this.getItemFromArrayByID(this.models, modelID);
                    if (!_.isNil(foundModel)) {
                        axios
                            .put('/api/models/' + modelID, foundModel)
                            .then(response => {
                                this.error = response.data.error;

                                if (response.status === HTTP_OK) {
                                    foundModel.edit = false;
                                }
                            })
                            .catch(error => {
                                this.error = this.createErrorFromRequest(error);
                            })
                        ;
                    }
                }
            },
            deleteModel(modelID) {
                if (modelID && _.isNumber(modelID)) {
                    axios
                        .delete('/api/models/' + modelID)
                        .then(response => {
                            this.error = response.data.error;

                            if (response.status === HTTP_OK) {
                                _.remove(this.models, (model) => model.id === modelID);
                            }
                        })
                        .catch(error => {
                            this.error = this.createErrorFromRequest(error);
                        })
                    ;
                }
            },
            getGroups() {
                this.groups = [];

                axios
                    .get('/api/groups', {
                        params: {
                            with_names: true,
                        },
                    })
                    .then(response => {
                        this.groups = response.data.result;
                    })
                    .catch(error => {
                        this.error = this.createErrorFromRequest(error);
                    })
                ;
            },
            saveGroup(groupID) {
                if (groupID && _.isNumber(groupID)) {
                    let foundGroup = this.getItemFromArrayByID(this.groups, groupID);
                    if (!_.isNil(foundGroup)) {
                        axios
                            .put('/api/groups/' + groupID, {
                                group: foundGroup,
                            })
                            .then(response => {
                                this.error = response.data.error;

                                if (response.status === HTTP_OK) {
                                    foundGroup.edit = false;
                                }
                            })
                            .catch(error => {
                                this.error = this.createErrorFromRequest(error);
                            })
                        ;
                    }
                }
            },
            deleteGroup(groupID) {
                if (groupID && _.isNumber(groupID)) {
                    axios
                        .delete('/api/groups/' + groupID)
                        .then(response => {
                            this.error = response.data.error;

                            if (response.status === HTTP_OK) {
                                let group = this.getItemFromArrayByID(this.groups, groupID);
                                if (!_.isNil(group)) {
                                    _.remove(this.groups, (group) => group.id === groupID);
                                }
                            }
                        })
                        .catch(error => {
                            this.error = this.createErrorFromRequest(error);
                        })
                    ;
                }
            },
            filterProducts() {
                let filters = {};

                if (this.searchProductNumber) {
                    filters['search'] = this.searchProductNumber;
                }

                this.getProducts('', filters);
            },
            getProducts(url, params = {}) {
                this.products = [];

                if (!url) {
                    url = '/api/products';
                }

                params.with_numbers = true;
                params.with_names = true;
                params.with_files = true;
                params.with_figures = true;

                axios
                    .get(url, { params })
                    .then(response => {
                        if (response.status === HTTP_OK) {
                            this.pagination = response.data.result;
                            this.products = response.data.result.data;

                            this.productsChanged = true;
                        }
                    })
                    .catch(error => {
                        this.error = this.createErrorFromRequest(error);
                    })
                ;
            },
            saveProduct(productID) {
                this.error = new _Error();

                if (productID && _.isNumber(productID)) {
                    let product = this.getItemFromArrayByID(this.products, productID);

                    if (!_.isNil(product)) {
                        axios
                            .put('/api/products/' + productID, {
                                product: product,
                            })
                            .then(response => {
                                this.error = response.data.error;

                                product.edit = false;

                                this.productsChanged = true;
                            })
                            .catch(error => {
                                this.error = this.createErrorFromRequest(error);
                            })
                        ;
                    }
                }
            },
            deleteProduct(productID) {
                axios
                    .delete('/api/products/' + productID)
                    .then(response => {
                        this.error = response.data.error;

                        if (response.status === HTTP_OK) {
                            _.remove(this.products, (product) => product.id === productID);

                            this.productsChanged = true;
                        }
                    })
                    .catch(error => {
                        this.error = this.createErrorFromRequest(error);
                    })
                ;
            },
            getInfoFiles() {
                this.infoFiles = [];

                axios
                    .get('/api/products/info-files')
                    .then(response => {
                        this.infoFiles = response.data.result;
                    })
                    .catch(error => {
                        this.error = this.createErrorFromRequest(error);
                    });
            },
        },
    };
</script>
